///
/// @file template_api/products/product_info_area_v1/src/product_info_area_v1.scss
///
/// \brief Styles for product info area - v1
///
///

@import '../../../../scss/theme-bootstrap';

.product-info-area {
  @include pie-clearfix;
  background: $white;
  padding: 1em;
  position: relative;
  &__header-group {
    float: $ldirection;
    width: 20%;
  }
  &__detail-group {
    float: $ldirection;
    width: 75%;
    margin-#{$ldirection}: 5%;
    @include breakpoint($landscape-up) {
      padding-top: 1em;
    }
  }
  &__header {
    @include breakpoint($landscape-up) {
      padding: 0 0 0.5em 0;
      margin: 1em 0;
    }
    @if $typography_cr24 == true {
      @include heading-sm-bold;
    } @else {
      line-height: 1;
      text-transform: uppercase;
    }
    border-bottom: #000 solid 1px;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      @if $typography_cr24 == false {
        font-weight: 700;
      }
    }
  }
  &__info {
    @if $typography_cr24 == true {
      @include body-r;
    }
    strong {
      @include body-xs-uppercase;
      font-weight: 700;
      @include breakpoint($landscape-up) {
        font-weight: 700;
      }
    }
  }
  ul,
  li {
    list-style: disc;
  }
  &__info-product-name {
    @if $typography_cr24 == true {
      @include body-r-bold;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        text-transform: uppercase;
      }
    } @else {
      text-transform: lowercase;
    }
  }
  // ingredients area
  &__ingredient {
    float: $ldirection;
    width: 50%;
  }
  &__ingredients-#{$ldirection} {
    float: $ldirection;
    width: 30%;
    @include breakpoint($landscape-up) {
      width: 50%;
    }
  }
  &__ingredients-right {
    float: $rdirection;
    width: 70%;
    @include breakpoint($landscape-up) {
      width: 50%;
    }
    &.full-width {
      width: 100%;
    }
  }
  .icon {
    #{$rdirection}: 0;
    width: 10px;
    height: 10px;
    &.collapsible-block__icon {
      width: 14px;
      height: 14px;
      margin-top: 3px;
    }
  }
  .product-full__top & {
    padding: 1em 0;
    .collapsible-block__title {
      @if $typography_cr24 == true {
        @include heading-sm-bold;
      } @else {
        font-weight: bold;
      }
    }
    &__header-group {
      width: 100%;
    }
    &__header {
      .icon {
        @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
        position: relative;
        float: $rdirection;
        width: 19px;
        height: 19px;
        display: none;
      }
      .icon--plus {
        display: block;
      }
      &.active {
        .icon--minus {
          display: block;
        }
        .icon--plus {
          display: none;
        }
      }
    }
    &__detail-group {
      width: 100%;
      margin-#{$ldirection}: 0;
      @include breakpoint($landscape-up) {
        padding-top: 0;
      }
    }
    &.collapsible-block {
      .icon {
        @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
        font-size: 10px;
        position: relative;
        float: $rdirection;
        margin-top: 3px;
        display: none;
      }
      .icon--minus {
        display: block;
      }
      &--alt {
        .icon--plus {
          display: block;
        }
        .icon--minus {
          display: none;
        }
      }
    }
  }
  .product-full__bottom & {
    &.collapsible-block {
      .icon {
        display: none;
      }
      .icon--caret--down {
        display: block;
      }
      &--alt {
        .icon--caret--right {
          display: block;
        }
        .icon--caret--down {
          display: none;
        }
      }
    }
  }
  .content-block-large__content {
    padding: 0;
  }
}
